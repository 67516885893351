import { customScrollbarStyle } from '@idaho-aeyc/ui-kit';
import { createGlobalStyle } from 'styled-components';
import { generateSpaceClassNames } from 'helpers/utils';

const spaces = [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40];

export const GenericStyles = createGlobalStyle`
${() => generateSpaceClassNames(spaces)}

${customScrollbarStyle}

.text-underline{
    text-decoration: underline;
  }
  .text-left {
    text-align: left;
  }
  .text-right {
    text-align: right;
  }
  .text-center {
    text-align: center;
  }

 .word-wrap-break-word {    word-wrap: break-word;}

  .h-100 {
    height: 100%;
  }

  .h-100vh {
    height: 100vh;
  }

  .w-100 {
    width: 100%;
  }

  .w-fit-content {
    width: fit-content
  }

  .mr-auto {
    margin-right: auto
  }
  .ml-auto {
    margin-left: auto
  }

  .m-auto {
    margin: 0 auto
  }

  .pointer {
    cursor: pointer;
  }
 
  .not-allowed {
    cursor: not-allowed;
  }

  .no-border {
    border:none !important;
  }

  .position-absolute {
    position: absolute
  }

  .position-relative {
    position: relative
  }

  .white-space-no-wrap {
    white-space: nowrap;
  }

  .word-break {
    word-break: break-word;
  }

  .flex-direction-column {
    flex-direction: column;
  }

  .font-thin {
    font-weight: 100 !important;
  }
    
  .font-extralight {
    font-weight: 200 !important;
  }
    
  .font-light {
    font-weight: 300 !important;
  }
    
  .font-normal {
    font-weight: 400 !important;
  }
    
  .font-medium {
    font-weight: 500 !important;
  }
    
  .font-semibold {
    font-weight: 600 !important;
  }
    
  .font-bold {
    font-weight: 700 !important;
  }
    
  .font-extrabold {
    font-weight: 800 !important;
  }
    
  .font-black {
    font-weight: 900 !important;
  }
    

  .ellipsis {
    word-break: break-all;
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
    vertical-align: middle
  }
  
  .ant-menu {
      &::after {
         display: block;
        }
        .ant-menu-submenu-arrow {
          inset-inline-end: 32px;
        } 
        
        .ant-menu-submenu-open,
        .ant-menu-submenu-selected {
          .ant-menu-submenu-arrow { 
          inset-inline-end: 32px;
          color:${({ theme }) => theme.GlobalStyles} ;
        }
    }
  }

  // TODO: NONA | MARTIROS find a way to import dropdown global styles frim ui-kit
  .ant-table-filter-dropdown-btns {
    gap: ${({ theme }) => theme.paddingXs}px;
    .ant-btn {
      width: 50%;
      border-radius: ${({ theme }) => theme.buttonBorderRadiusLg}px;
      font-weight: ${({ theme }) => theme.buttonFontWeightMd};
      box-shadow:none;
      min-height: 32px;
    }
    .ant-btn-link {
      color: ${({ theme }) => theme.colorBlackPrimary};
      border: ${({ theme }) => theme.buttonBorderLg}
      ${({ theme }) => theme.buttonColorPrimaryNormalBg} ;
       &:disabled { 
          // styled as serach filter reset button
        color: ${({ theme }) => theme.buttonColorPrimaryDisabledText} ;
        background-color: ${({ theme }) =>
          theme.buttonColorPrimaryDisabledBg} ; 
          border-color: ${({ theme }) => theme.buttonColorPrimaryDisabledBg}; 
      }
    }
  }

  .ant-dropdown {
    .ant-table-filter-dropdown {
      min-width: 150px;
    }
    &.button-dropdown {
      .ant-dropdown-menu {
        .ant-dropdown-menu-item {
          .ant-dropdown-menu-title-content{
            flex: none;
            width: 100%;
          }
        }
      .ant-dropdown-menu-submenu-expand-icon {
        display: none
    }
      }
    }
  }
.ant-dropdown-menu-submenu {
  .ant-dropdown-menu {
      .ant-dropdown-menu-item-group {
          .ant-dropdown-menu-item-group-title {
            font-weight: ${({ theme }) => theme.fontWeightSemiBold} 
          }
        }
      }
}

  .pac-container {
    z-index: 9999;
    height: 174px;
    display: flex;
    flex-direction: column-reverse;
    background: none;
    box-shadow: none;
    border-top: 0;
    .pac-item {
      background-color: ${({ theme }) => theme.colorWhitePrimary} ;
      border-left:1px solid ${({ theme }) => theme.colorBlackBorder}  ;
      border-right: 1px solid ${({ theme }) => theme.colorBlackBorder} ;
    }
    .pac-item:last-child {
      border-top: 1px solid ${({ theme }) => theme.colorBlackBorder} ;
    }
  }
.pac-container::after{
  // for google logo
  // border-bottom: none;
  // order: 3;
  display: none;
}

 .ant-table-filter-dropdown-checkall {
  display: none !important;
 }

.disabled-registrant-row {
    .ant-table-cell {
      &:not(:last-child) {
        opacity: 0.5;
      }
      &-fix-right {
        background: ${({ theme }) => theme.tableColorRowHover} !important;
        > * {
          > * {
            &:not(:last-child) {
              opacity: 0.5;
            }
            .edit-button {
              opacity: 1;
            }
          }
        }
      }
    }
    background: ${({ theme }) => theme.tableColorRowHover};
  }

  // notification styling
  .ant-notification-notice {
    &&.custom-notification {
        padding: 16px;
        border-radius: ${({ theme }) => theme.notificationBorderRadius}px;
        background-color: ${({ theme }) => theme.notificationColorBg};
        .ant-notification-notice-content {
          padding-right: 16px;
        }
        .ant-notification-notice-icon {
            display: none
        }
        .ant-notification-notice-message {
          margin-inline-start: 0;
          margin-bottom: 0;
          color: ${({ theme }) => theme.colorWhitePrimary};
        }
        .ant-notification-notice-close {
          top: 16px;
        }
    }
  }
`;
